import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import CloseIcon from '../../../components/Common/CloseIcon';
import AssignToProjectForm from './AssignToProjectForm';
import { toggleAssignAllModal } from '../../../store/records';
import { clearRelatedRetirement } from '../../../store/projects';
import { useProjects } from '../../../hooks';
import { format } from '../../../helpers/formatter';

const Header = styled(ModalHeader)`
  padding: 16px 16px 0 16px;
  border-bottom: 1px #d3d3d3 solid;
`;

const TotalsHeader = styled(ModalHeader)`
  padding: 16px 16px 0 16px;
  border-bottom: 1px #d3d3d3 solid;

  .modal-title {
    width: 100%;
    padding: 0 28px;
  }
`;

const TitleCol = styled(Col)`
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 400;
`;

const TotalRow = styled(Row)`
  margin-bottom: 15px;
  font-size: 13px;
  font-weight: 400;
`;

const StyledModal = styled(Modal)`
  width: 430px;
`;

const CloseButton = styled(Button)`
  width: auto;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 14px 16px;
  border: none;
`;

const StyledButton = styled(Button)`
  display: inline-block;
  width: auto;
  height: 37px;
  padding: 4px 20px;
  margin-left: 10px;
`;

const AssignToProject = ({ setIsOpen, isOpenAssignModal, params }) => {
  const dispatch = useDispatch();
  const { data } = useProjects();

  const toggleModal = async () => {
    dispatch(clearRelatedRetirement());
    setIsOpen(!isOpenAssignModal);
    return dispatch(toggleAssignAllModal());
  };

  const { totalOffsetOfNew, withStatusNew } = useSelector((state) => ({
    totalOffsetOfNew: state.records.records.totalOffsetOfNew,
    withStatusNew: state.records.records.withStatusNew,
  }));

  return (
    <>
      <StyledButton
        color="primary"
        disabled={!withStatusNew || withStatusNew === 0}
        onClick={toggleModal}
      >
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <span>Assign all "new" records</span>
      </StyledButton>
      <StyledModal toggle={toggleModal} isOpen={isOpenAssignModal}>
        <Header>
          <Row>
            <TitleCol>Assign to project</TitleCol>
            <CloseButton color="link" onClick={toggleModal}>
              <CloseIcon />
            </CloseButton>
          </Row>
        </Header>
        <TotalsHeader>
          <TotalRow>
            <Col xs={8}>Total selected</Col>
            <Col xs={4} style={{ fontWeight: '500' }}>
              {format(withStatusNew)}
            </Col>
          </TotalRow>
          <TotalRow>
            <Col xs={8}>Total remaining carbon</Col>
            <Col xs={4} style={{ fontWeight: '500' }}>
              {format(totalOffsetOfNew)} tonne
            </Col>
          </TotalRow>
        </TotalsHeader>
        <ModalBody>
          <AssignToProjectForm
            params={params}
            isOpenAssignModal={isOpenAssignModal}
            setIsOpen={setIsOpen}
            data={data}
          />
        </ModalBody>
      </StyledModal>
    </>
  );
};

export default AssignToProject;

AssignToProject.propTypes = {
  setIsOpen: PropTypes.func,
  params: PropTypes.object.isRequired,
  isOpenAssignModal: PropTypes.bool.isRequired,
};
