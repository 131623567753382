import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import AssignToProject from '../../pages/AdvancedSearch/BulkActions/AssignToProject';
import MultiProcessRecord from '../../pages/AdvancedSearch/BulkActions/ProcessRecord';
import MoveToBilled from '../../pages/AdvancedSearch/BulkActions/MoveToBilled';
import { withUser } from '../../context/UserContext';
import { format } from '../../helpers/formatter';

import PageTitle from '../Common/PageTitle';
import Export from '../../pages/AdvancedSearch/Export';
import ViewDetails from '../../pages/AdvancedSearch/ViewDetails';

const PageContent = styled.div`
  padding: 94px 12px 24px 12px;
`;

const TablePage = ({
  children,
  title,
  subTitle,
  filteredCount,
  count,
  params,
  user,
}) => {
  const [isAssignOpen, setAssign] = useState(false);
  const [isBillOpen, setBill] = useState(false);
  return (
    <PageContent>
      <PageTitle title={title} />
      <Container fluid>
        <Row>
          <Col className="col-12">
            <div className="mb-2">
              <div className="d-flex justify-content-between ">
                <h3 className="mr-2">{title}</h3>
                {title === 'Records' ? (
                  <span>
                    <Export params={params} />
                    {user.role === 'ADMIN' || user.role === 'SALES' ? (
                      <>
                        <ViewDetails />
                        <AssignToProject
                          setIsOpen={setAssign}
                          isOpenAssignModal={isAssignOpen}
                          params={params}
                        />
                        <MultiProcessRecord params={params} />
                        <MoveToBilled
                          setIsOpen={setBill}
                          isOpenBilledModal={isBillOpen}
                          params={params}
                        />
                      </>
                    ) : null}
                  </span>
                ) : null}
              </div>
            </div>
            <Card>
              <CardBody>
                <CardTitle tag="span" className="h4">
                  {subTitle}
                </CardTitle>
                <span className="ml-4">
                  {count &&
                    ` ${format(filteredCount)}/${format(count)} records`}
                </span>
                {children}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </PageContent>
  );
};

TablePage.defaultProps = {
  filteredCount: null,
  count: null,
};

TablePage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  filteredCount: PropTypes.number,
  count: PropTypes.number,
  params: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withUser(TablePage);
