import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication
import Login from './auth/login/reducer';
import ForgetPassword from './auth/forgetpwd/reducer';

// TODO: delete
import calendar from './calendar/reducer';
import chat from './chat/reducer';
import crypto from './crypto/reducer';
import invoices from './invoices/reducer';
import tasks from './tasks/reducer';
import contacts from './contacts/reducer';
import mails from './mails/reducer';
// ClimeCo
import records from './records/reducer';
import users from './users/reducer';
import svcpns from './svcpns/reducer';
import projects from './projects/reducer';
import retirements from './retirements/reducer';
import allProjects from './allProjects/reducer';
import billings from './billings/reducer';
import emailRecipients from './emailRecipients/reducer';
import transmissionLogs from './transmissionLogs/reducer';

const rootReducer = combineReducers({
  allProjects,
  billings,
  records,
  users,
  svcpns,
  projects,
  retirements,
  emailRecipients,
  transmissionLogs,
  // Delete
  Layout,
  Login,
  ForgetPassword,
  // TODO: will be deprecated
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  tasks,
  contacts,
});

export default rootReducer;
