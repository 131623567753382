import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  Form,
  Input,
  FormFeedback,
  Label,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { verifySMSCode, sendSMSCode, clearUserError } from '../../store/users';

const SmsAuthenticator = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [resendTimer, setResendTimer] = useState(60);
  const { currentUser } = useSelector((state) => ({
    currentUser: state.users.currentUser,
  }));
  const { error } = useSelector((state) => ({
    error: state.users.error,
  }));
  const { rememberMe } = useSelector((state) => ({
    rememberMe: state.users.rememberMe,
  }));

  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer(resendTimer - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [resendTimer]);

  useEffect(() => {
    dispatch(clearUserError());
  }, []);

  useEffect(() => {
    if (!currentUser) {
      history.push('/login');
    }
    if (currentUser) {
      dispatch(sendSMSCode({ params: { email: currentUser.email } }));
    }
  }, [dispatch, currentUser, history]);

  const handleResendCode = async () => {
    if (resendTimer > 0) return;

    try {
      dispatch(sendSMSCode({ params: { email: currentUser.email } }));
      setResendTimer(60);
    } catch (err) {
      setError('Failed to resend code. Please try again later.');
    }
  };

  const validation = useFormik({
    initialValues: { smsCode: '' },
    validationSchema: Yup.object({
      smsCode: Yup.string()
        .required('Please enter the verification code')
        .matches(/^\d{6}$/, 'Code must be a 6-digit number'),
    }),
    onSubmit: (values) => {
      dispatch(
        verifySMSCode({
          params: {
            code: values.smsCode,
            email: currentUser.email,
            rememberMe,
          },
          history,
        }),
      );
    },
  });

  const maskedPhoneNumber = () => {
    if (!currentUser?.phoneNumber) return '';

    return `${currentUser?.phoneNumber?.slice(0, 5)}${'*'.repeat(
      // eslint-disable-next-line no-unsafe-optional-chaining
      currentUser?.phoneNumber?.length - 7,
    )}${currentUser?.phoneNumber?.slice(-2)}`;
  };

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card
              className="overflow-hidden p-4"
              style={{
                minHeight: '500px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardBody className="d-flex flex-column">
                <h4 className="text-left mb-4">SMS Code Authentication</h4>
                <p className="text-muted text-left">
                  We have sent your authentication code to
                  {maskedPhoneNumber()}
                </p>
                <Form
                  onSubmit={validation.handleSubmit}
                  className="d-flex flex-column"
                  style={{ flex: 1, justifyContent: 'space-between' }}
                >
                  <div>
                    <div className="mb-3">
                      <Label>Verification Code</Label>
                      <Input
                        name="smsCode"
                        type="text"
                        placeholder="XXXXXX"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.smsCode}
                        invalid={
                          !!(
                            validation.touched.smsCode &&
                            validation.errors.smsCode
                          ) || !!error
                        }
                      />
                      {validation.touched.smsCode &&
                        validation.errors.smsCode && (
                          <FormFeedback type="invalid">
                            {validation.errors.smsCode}
                          </FormFeedback>
                        )}
                      {error && !validation.errors.smsCode && (
                        <FormFeedback type="invalid">{error}</FormFeedback>
                      )}
                    </div>

                    <div className="text-left mb-4">
                      <p className="text-muted">
                        Didn’t get a code?{' '}
                        <Button
                          color="link"
                          onClick={handleResendCode}
                          disabled={resendTimer > 0}
                        >
                          Resend code
                          {resendTimer > 0 && `(In ${resendTimer}s)`}
                        </Button>
                      </p>
                    </div>
                  </div>

                  <div>
                    <div className="d-grid">
                      <Button
                        color="primary"
                        type="submit"
                        disabled={validation.values.smsCode.length < 1}
                      >
                        Verify
                      </Button>
                    </div>
                    <div className="mt-2 text-center">
                      <Button
                        color="link"
                        onClick={() => history.push('/2fa-login')}
                      >
                        &lt; Back
                      </Button>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SmsAuthenticator;
