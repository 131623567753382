import { asyncActionType } from '../common';

export const GET_RECORDS = asyncActionType('GET_RECORDS');
export const FETCH_FILTERS = asyncActionType('FETCH_FILTERS');
export const TOGGLE_ASSIGN_RECORDS_MODAL = asyncActionType(
  'TOGGLE_ASSIGN_RECORDS_MODAL',
);
export const TOGGLE_BILLED_RECORDS_MODAL = asyncActionType(
  'TOGGLE_BILLED_RECORDS_MODAL',
);
export const ASSIGN_RECORDS_TO_PROJECT = asyncActionType(
  'ASSIGN_RECORDS_TO_PROJECT',
);
export const PROCESS_RECORD = asyncActionType('PROCESS_RECORD');
export const MOVE_RECORDS_TO_BILLED = asyncActionType('MOVE_RECORDS_TO_BILLED');
export const SET_ROW_DATA_ARRAY = asyncActionType('SET_ROW_DATA_ARRAY');
export const TOGGLE_ASSIGN_ALL_MODAL = asyncActionType(
  'TOGGLE_ASSIGN_ALL_MODAL',
);
export const ASSIGN_ALL_TO_PROJECT = asyncActionType('ASSIGN_ALL_TO_PROJECT');
export const EDIT_RECORD = asyncActionType('EDIT_RECORD');
export const CLEAR_EDIT_RECORD_ERROR = asyncActionType(
  'CLEAR_EDIT_RECORD_ERROR',
);
export const EXPORT_CSV = asyncActionType('EXPORT_CSV');
export const BULK_ACTION = asyncActionType('BULK_ACTION');
export const MOVE_TO_BILLED = asyncActionType('MOVE_TO_BILLED');
