import React, { useMemo } from 'react';
import { Row, Col, CardTitle, Container, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import TransmissionLogsTable from '../../components/Tables/TransmissionLogsTable';
import { useTransmissionLogs } from '../../hooks';
import PageTitle from '../../components/Common/PageTitle';
import TransmissionLogView from './TransmissionLogView';
import { withUser } from '../../context/UserContext';
import SortCaret from '../../components/Tables/SortCaret';

const TruncateCell = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 25vw;
`;

const columns = [
  {
    dataField: 'createdAt',
    text: 'Timestamp',
    formatter: (cell) => moment(cell).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    dataField: 'requestBody',
    text: 'Request',
    formatter: (cell) => <TruncateCell>{cell}</TruncateCell>,
  },
  {
    dataField: 'responseStatus',
    text: 'Response Status Code',
    sort: true,
  },
  {
    dataField: 'responseBody',
    text: 'Detailed Response',
    formatter: (cell) => <TruncateCell>{cell}</TruncateCell>,
  },
  {
    dataField: 'action',
    text: 'Action',
    formatter: (_, row) => <TransmissionLogView row={row} />,
  },
];

const TransmissionLog = () => {
  const {
    transmissionLogsData,
    params,
    count,
    updateParams,
    sortableColumns,
    isTransmissionLogsLoading: isLoading,
  } = useTransmissionLogs();

  const columnsWithSorting = useMemo(
    () =>
      columns.map((column) => ({
        ...column,
        sort: sortableColumns.includes(column.dataField),
        sortCaret: (order) => <SortCaret order={order} />,
      })),
    [sortableColumns.join('')],
  );

  const onTableChange = (type, newState) => {
    if (type === 'pagination') {
      updateParams({
        ...params,
        page: newState.page,
        pageSize: newState.sizePerPage,
      });
    } else if (type === 'sort') {
      updateParams({
        ...params,
        sortingColumn: newState.sortField,
        sortingDirection: newState.sortOrder,
      });
    }
  };

  return (
    <div className="page-content">
      <PageTitle title="Event Log" />
      <Container fluid>
        <Row>
          <Col xs={10} className="mb-2">
            <h3>Event Log</h3>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <Card>
              <CardBody>
                <div>
                  <CardTitle tag="span" className="h4">
                    Incoming Transmissions Logs
                  </CardTitle>
                </div>
                <TransmissionLogsTable
                  columns={columnsWithSorting}
                  isLoading={isLoading}
                  data={transmissionLogsData || []}
                  page={params.page}
                  sizePerPage={params.pageSize}
                  totalSize={count}
                  onTableChange={onTableChange}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

TransmissionLog.propTypes = {
  user: PropTypes.object.isRequired,
};

export default withUser(TransmissionLog);
