/* eslint-disable prettier/prettier */
import React from 'react';
import { Redirect } from 'react-router-dom';

// Authentication related pages
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import ForgetPwd from '../pages/Authentication/ForgetPassword';
import TwoFactorAuthMethods from '../pages/Authentication/TwoFactorAuthMethods';
import SmsAuthenticator from '../pages/Authentication/SmsAuthenticator';
import OTPAuthenticator from '../pages/Authentication/OTPAuthenticator';
import OTPRegister from '../pages/Authentication/OTPRegister';
import AdvancedSearch from '../pages/AdvancedSearch';
import SVCPNs from '../pages/SVCPN/index';
import Users from '../pages/User/index';
import { Settings, EmailRecipients } from '../pages/Settings/index';
import { NotFound } from '../pages/errors';
import Projects from '../pages/Projects/Projects';
import Reirements from '../pages/Retirements/index';
import BillingSummaryPage from '../pages/BillingSummary/index';
import EmailSent from '../pages/Authentication/EmailSent';
import ChangePassword from '../pages/Authentication/ChangePassword';
import EventLog from '../pages/TransmissionLogs/TransmissionLog';

const authProtectedRoutes = [
  { path: '/billings', component: BillingSummaryPage },
  { path: '/home', component: AdvancedSearch },
  { path: '/svcpns', component: SVCPNs },
  { path: '/users', component: Users },
  { path: '/projects', component: Projects },
  { path: '/retirements', component: Reirements },
  { path: '/settings', component: Settings },
  {
    path: '/settings/processing-recipients',
    component: (props) => <EmailRecipients {...props} type="PROCESSING" />,
  },
  {
    path: '/settings/failed-transmission-recipients',
    component: (props) => <EmailRecipients {...props} type="FAILED_TRANSMISSION" />,
  },
  { path: '/event-log', component: EventLog },

  // this route should be at the end of all other routes
  // TODO: tmp
  // eslint-disable-next-line react/display-name
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/home" />,
  },
  { component: NotFound },
];

const publicRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/2fa-login', component: TwoFactorAuthMethods },
  { path: '/2fa-login/sms', component: SmsAuthenticator },
  { path: '/2fa-login/authenticator', component: OTPAuthenticator },
  { path: '/2fa-login/authenticator/register', component: OTPRegister },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/email-sent', component: EmailSent },
  { path: '/reset-password', component: ChangePassword },
];

export { publicRoutes, authProtectedRoutes };
